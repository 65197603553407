.stage-cube-cont {
    width: 50%;
    height: 100%;
    top: 0;
    padding-top: 18%;
    margin-left: 0;
    position: absolute;
    right: 0;
}

.cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);

    div {
        position: absolute;
        width: 200px;
        height: 200px;
        border: 1px solid #ccc;
        background: rgba(255, 255, 255, 0.4);
        font-size: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px 0px lightyellow;
    }

    .face1 {
        transform: translateZ(100px);
    }

    .face2 {
        transform: rotateY(90deg) translateZ(100px);
    }

    .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
    }

    .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
    }

    .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
    }

    .face6 {
        transform: rotateX(-90deg) translateZ(100px);
    }

}

@keyframes spincube {
    from,
    to {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0)
    }
    16% {
        transform: rotateY(-90deg);
    }
    33% {
        transform: rotateY(-90deg) rotateZ(90deg);
    }
    50% {
        transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
        transform: rotateY(-270deg) rotateZ(90deg);
    }
    83% {
        transform: rotateX(90deg);
    }
}

@media (max-width: 768px) {
    .stage-cube-cont {
        width: 100%;
        height: 10%;
        bottom: 5%;
        top: auto;
        margin: 0;
        padding: 0;
    }

    .cubespinner {
        transform-origin: 35px 35px 0;
        margin-left: calc(50% - 35px);

        div {
            height: 70px;
            width: 70px;
            font-size: 65px;
        }
        .face1 {
            transform: translateZ(35px);
        }
    
        .face2 {
            transform: rotateY(90deg) translateZ(35px);
        }
    
        .face3 {
            transform: rotateY(90deg) rotateX(90deg) translateZ(35px);
        }
    
        .face4 {
            transform: rotateY(180deg) rotateZ(90deg) translateZ(35px);
        }
    
        .face5 {
            transform: rotateY(-90deg) rotateZ(90deg) translateZ(35px);
        }
    
        .face6 {
            transform: rotateX(-90deg) translateZ(35px);
        }
    }
}