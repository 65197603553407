.project-titles {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    

    h2 {
        padding: 5px;
        width: 24%;
        margin-right: 1%;
        text-align: center;
        color: #ffd700;
        font-family: 'Coolvetica';
        font-weight: 300;
        border: 2px solid #ffd700;
        cursor: pointer;

        &:hover {
            background: #ffd700;
            color: #333
        }
    }
}

.iframe-container{
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; 
    height: 0;
}

.iframe-container iframe{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }